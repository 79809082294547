iframe.ql-video {
    width: 100% !important;
    height: 389px !important;
}

.btn {
    color: #fff;
}

.category-link {
    color: #fff;
    font-size: 20px;
}

.card-title {
    font-size: 1.5rem;
}
